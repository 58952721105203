<template>
  <div class="search-page">
    <template v-if="loadingInit">...</template>
    <template v-else>
      <v-tabs v-model="tab" centered @change="onTabChange">
        <v-tab href="#search">All Messages</v-tab>
        <v-tab href="#photo">Photos</v-tab>
        <v-tab href="#video">Videos</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- SUGGEST fix this to loop render ? -->
        <v-tab-item id="search">
          <SearchPageContent
            type="search"
            :loading="loadingContent"
            :searchResult="searchResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="photo">
          <SearchPageContent
            type="photo"
            :loading="loadingContent"
            :searchResult="photoResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
        <v-tab-item id="video">
          <SearchPageContent
            type="video"
            :loading="loadingContent"
            :searchResult="videoResult"
            @changePage="onPageChange"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/services/api';

import SearchPageContent from '@/components/Search/SearchPageContent.vue';

export default {
  name: 'Search',
  components: {
    SearchPageContent,
  },
  data() {
    return {
      loadingInit: true,
      loadingContent: true,
      tab: 'null',
      recordPerPage: 10,
      searchResult: {},
      photoResult: {},
      videoResult: {},
    };
  },
  async created() {
    await this.$store.dispatch('filter/initFilterCriteria').catch(() => {});
    this.loadingInit = false;
  },
  methods: {
    ...mapActions({
      highlightKeywords: 'config/highlightKeywords',
    }),
    onTabChange(tab) {
      console.log('Tab', tab);
      this.getSearchData();
    },
    async setDataByType(result, type) {
      if (result && result.message) {
        // Do highlight
        await this.highlightKeywords({
          messageList: result.message.data,
        });
        if (type === 'search') {
          this.searchResult = result.message;
        } else if (type === 'photo') {
          this.photoResult = result.message;
        } else if (type === 'video') {
          this.videoResult = result.message;
        }
      } else {
        if (type && type !== 'null') {
          if (type === 'search') {
            this.searchResult = [];
          } else if (type === 'photo') {
            this.photoResult = [];
          } else if (type === 'video') {
            this.videoResult = [];
          }
        }
      }
    },
    async getSearchData() {
      this.loadingContent = true;
      const data = JSON.parse(JSON.stringify(this.filterCriteria));
      data.paging = {
        recordPerPage: this.recordPerPage,
        page: 1,
      };
      let result = { message: [] };
      let e = false;
      if (this.tab === 'search') {
        result = await api.getSearchData(data).catch(() => (e = true));
      } else if (this.tab === 'photo') {
        result = await api.getSearchImage(data).catch(() => (e = true));
      } else if (this.tab === 'video') {
        result = await api.getSearchVideo(data).catch(() => (e = true));
      }
      if (e) {
        this.$toast.error(`Error! Cannot get ${this.tab} data.`);
      }
      this.setDataByType(result, this.tab);
      this.loadingContent = false;
    },
    async onPageChange(obj) {
      this.loadingContent = true;
      const { page, type } = obj;
      const paging = {
        recordPerPage: this.recordPerPage,
        page,
      };
      let criteria, result;
      if (type === 'search') {
        criteria = { ...this.searchResult.criteria, paging };
        result = await api.getSearchData(criteria).catch(() => {});
      } else if (type === 'photo') {
        criteria = { ...this.photoResult.criteria, paging };
        result = await api.getSearchImage(criteria).catch(() => {});
      } else if (type === 'video') {
        criteria = { ...this.videoResult.criteria, paging };
        result = await api.getSearchVideo(criteria).catch(() => {});
      }
      this.setDataByType(result, type);
      this.loadingContent = false;
      // Scroll to top
    },
  },
  watch: {
    filterCriteria: {
      handler: 'getSearchData',
    },
  },
  computed: {
    ...mapState({
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
